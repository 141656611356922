import User from '../models/User';
import Product from '../models/Product';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

/**
 * Gets the id of the logged in user
 */
const userId = (): string | undefined => {
    return firebase.auth().currentUser?.uid;
};

const doResetPassword = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Gets the user from the Firestore.
 */
const doGetUsers = (): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
    return firebase.firestore().collection('users').doc(userId()).get();
};

/**
 * Sets the given user in the Firestore.
 * @param user The given user
 */
const doSetUsers = (user: User): Promise<void> => {
    return firebase.firestore().collection('users').doc(userId()).set(user);
};

/**
 * Gets the given product from the Firestore.
 * @param id
 */
const doGetProducts = (id: string): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
    return firebase.firestore().collection('products').doc(id).get();
};

/**
 * Sets the given product in the Firestore.
 * @param product The given product
 * @param offerId The given offer id
 */
const doSetProducts = (product: Product, offerId: string): Promise<void> => {
    return firebase
        .firestore()
        .collection('products')
        .doc(product.id + offerId)
        .set(product);
};

/**
 * Gets all the inventory entries for the given product.
 * @param trackerId The tracker id of the given product
 */
const doGetProductInventory = (
    trackerId: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
    return firebase.firestore().collection('productInventory').doc(trackerId).get();
};

/**
 * Gets the amount of trackers for the given product
 * @param trackerId of the given product
 */
const doGetTrackers = (
    trackerId: string,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> => {
    return firebase.firestore().collection('trackers').doc(trackerId).get();
};

/**
 * Sets the amount of trackers of the given product
 * @param amount the number of trackers
 * @param trackerId of the given product
 */
const doSetTrackers = (amount: number, trackerId: string): Promise<void> => {
    return firebase.firestore().collection('trackers').doc(trackerId).set({ count: amount });
};

/**
 * Logs the user into Firebase Authentication with the given e-mail and password.
 * @param email The given e-mail string
 * @param password The given password string
 */
const doLogin = (email: string, password: string): Promise<firebase.auth.UserCredential> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
};

/**
 * Logs the current user out of Firebase Authentication.
 */
const doLogout = (): Promise<void> => {
    return firebase.auth().signOut();
};

/**
 * Registers a user into Firebase Authentication with the given e-mail and password.
 * @param email The given e-mail string
 * @param password The given password string
 */
const doRegister = (email: string, password: string): Promise<firebase.auth.UserCredential> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export {
    doGetUsers,
    doSetUsers,
    doGetProducts,
    doSetProducts,
    doLogin,
    doLogout,
    doRegister,
    doGetProductInventory,
    doGetTrackers,
    doSetTrackers,
    doResetPassword,
    userId,
};
