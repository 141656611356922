import React, { useState } from 'react';
import './Commission.css';

const Commission = () => {
    const [ean, setEan] = useState<string>('');
    const [unitPrice, setUnitPrice] = useState<string>('');

    const getCommission = () => {
        if (ean !== '' && unitPrice !== '') {
            fetch(`${process.env.REACT_APP_SERVER_URL}/api/commission/${ean}/${unitPrice}`)
                .then((res) => res.json())
                .then((response) => alert(`De commissieprijs is: ${response.totalCost}`))
                .catch((error) => console.log('Error: ', error));
        } else {
            alert('Voer beide velden a.u.b. in.');
        }
    };

    return (
        <section className="Commission">
            <p className="commission-title">Commissie Berekenen</p>
            <p className="commission-subtitle">EAN</p>
            <input
                className="commission-input"
                placeholder="EAN"
                type="text"
                name="ean"
                value={ean}
                onChange={(e) => setEan(e.target.value)}
            />
            <div className="commission-bar" />
            <p className="commission-subtitle" id="subtitle-unit-price">
                Stuksprijs
            </p>
            <input
                className="commission-input"
                placeholder="Stuksprijs"
                type="text"
                name="unitPrice"
                value={unitPrice}
                onChange={(e) => setUnitPrice(e.target.value)}
            />
            <div className="commission-bar" />
            <button className="button-commission" onClick={getCommission}>
                Comissieprijs Berekenen
            </button>
        </section>
    );
};

export default Commission;
