import React, { useState, useEffect } from 'react';
import './Menu.css';
import { doLogout } from '../../service/FirebaseService';
import firebase from 'firebase/app';
import 'firebase/auth';
import {ReactComponent as Logo} from '../../img/logo-bollie-small.svg';
import {ReactComponent as List} from "../../img/list.svg";
import { ReactComponent as Logout } from '../../img/logout.svg';
import {ReactComponent as Calculate} from "../../img/calculate.svg";
import { Link, useLocation } from 'react-router-dom';

const Menu = () => {
    const [user, setUser] = useState<firebase.User | null>(firebase.auth().currentUser);
    const route = useLocation().pathname
    console.log(route)

    /**
     * Sets user when Firebase Authentication is changed.
     */
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
        });
    }, []);

    /**
     * Logs user out of Firebase Authentication.
     */
    const logout = () => {
        doLogout()
            .then(() => {
                setUser(null);
            })
            .catch((error) => console.error('Error: ', error));
    };

    return (
        <>
            <aside>
                {user ? (
                    <>
                        <div className="top-icons">

                            <Link to="/">
                                <Logo />
                            </Link>
                            <Link to="/" className={route == "/" ? "active" : "not-active"}>
                                <List className="menu-icon" />
                            </Link>
                            {/*<Link to="/commission" className={route == "/commission" ? "active" : "not-active"}>*/}
                            {/*    <Calculate className="menu-icon" />*/}
                            {/*</Link>*/}
                        </div>

                        <Logout className="logout-button" onClick={logout} />
                    </>
                ) : (
                    <Logo />
                )}
            </aside>
        </>
    );
};

export default Menu;
