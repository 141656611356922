import React, { useEffect, useState } from 'react';
import './App.css';
import Menu from './components/Menu/Menu';
import Login from './components/Login/Login';
import Loading from './components/Loading/Loading';
import Tracker from './components/Tracker/Tracker';
import Register from './components/Register/Register';
import Commission from './components/Commission/Commission';
import PageNotFound from './components/PageNotFound/PageNotFound';
import ProductOverview from './components/ProductOverview/ProductOverview';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Switch, Route } from 'react-router-dom';

const App = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<firebase.User | null>(firebase.auth().currentUser);

    /**
     * Sets user when Firebase Authentication is changed.
     */
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
            setIsLoading(false);
        });
    });

    if (isLoading) {
        return <Loading />;
    } else if (user === null) {
        return <Login />;
    }

    return (
        <div className="App">
            <Menu />
            <Switch>
                <Route exact path="/">
                    <Tracker />
                </Route>
                <Route path="/product/:trackerId">
                    <ProductOverview />
                </Route>
                <Route path="/commission">
                    <Commission />
                </Route>
                <Route path="/register">
                    <Register />
                </Route>
                <Route path="**">
                    <PageNotFound />
                </Route>
            </Switch>
        </div>
    );
};

export default App;
