import React, { useEffect, useState } from 'react';
import './Tracker.css';
import User from '../../models/User';
import Product from '../../models/Product';
import Category from '../../models/Category';
import TrackedProduct from '../../models/TrackedProduct';
import FirebaseInventory from '../../models/FirebaseInventory';
import {
    doGetProducts,
    doGetUsers,
    doSetUsers,
    doSetProducts,
    doGetProductInventory,
    doGetTrackers,
    doSetTrackers,
} from '../../service/FirebaseService';
import ProductList from '../ProductList/ProductList';
import ProductInventory from '../../models/ProductInventory';
import { ReactComponent as Search } from '../../img/search.svg';
import { ReactComponent as Delete } from '../../img/delete.svg';
import { ReactComponent as Add } from '../../img/add.svg';
import firebase from "firebase";

const Tracker = () => {
    const initUser: User = {
        firstName: '',
        lastName: '',
        products: [],
    };
    const [user, setUser] = useState<User>(initUser);
    const [search, setSearch] = useState<string>('');
    const [seconds, setSeconds] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [revenue, setRevenue] = useState<number>(0);
    const [category, setCategory] = useState<string>('');
    const [itemsSold, setItemsSold] = useState<number>(0);
    const [results, setResults] = useState<Product[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [productInventory, setProductInventory] = useState<Map<string, number>>(new Map());
    const [allInventory, setAllInventory] = useState<Map<string, ProductInventory[]>>(new Map());
    const [filteredInventory, setFilteredInventory] = useState<Map<string, ProductInventory[]>>(new Map());
    const [currentCategory, setCurrentCategory] = useState<string>('Alle');
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [range, setRange] = useState<string>('All');

    useEffect(() => {
        showTime();
        getProducts();
    }, []);

    /**
     * Gets all products from the current user.
     */
    const getProducts = () => {
        doGetUsers()
            .then((document) => {
                if (document.exists) {
                    const data = document.data() as User;
                    if (data) {
                        setUser(data);
                        if (data.products) {
                            const trackedProducts: TrackedProduct[] = data.products;
                            trackedProducts.forEach((trackedProduct) => {
                                doGetProducts(trackedProduct.productId + trackedProduct.offerId)
                                    .then((document) => {
                                        const newProduct = document.data() as Product;
                                        products.push(newProduct);
                                        getSales(
                                            trackedProduct.productId + trackedProduct.offerId,
                                            newProduct.offerData.offers[0].price,
                                        );
                                        if (document.exists) {
                                            setProducts([...products]);
                                            setFilteredProducts([...products]);
                                        }
                                    })
                                    .catch((error) => console.error('Error: ', error));
                            });
                        }
                    }
                }
            })
            .catch((error) => console.error('Error: ', error));
    };

    /**
     * Gets the sales data from the Firestore of the given product
     * @param trackerId from the given product
     * @param price from the given product
     */
    const getSales = (trackerId: string, price: number) => {
        doGetProductInventory(trackerId)
            .then((document) => {
                if (document.exists) {
                    const inventory: FirebaseInventory[] = document.data()!.productInventory as FirebaseInventory[];
                    const soldItems: number = inventory[inventory.length - 1].numberOfSales;
                    productInventory.set(trackerId, soldItems);
                    setProductInventory(productInventory);

                    console.log("tracker insert", trackerId)

                    const productInv: ProductInventory[] = []
                    inventory.forEach(inv => {
                        const newInv: ProductInventory = {
                            inventory: inv.inventory,
                            price: inv.price,
                            numberOfSales: inv.numberOfSales,
                            updated: inv.updated.toDate()
                        }
                        productInv.push(newInv)
                    })

                    allInventory.set(trackerId, productInv as ProductInventory[]);
                    setAllInventory(allInventory);
                    setFilteredInventory(allInventory)

                    setItemsSold((itemsSold) => itemsSold + soldItems);
                    setRevenue((revenue) => revenue + soldItems * price);
                }
            })
            .catch((error) => console.error('Error: ', error));
    };

    const filterInventory = (event: any) => {
        const date: Date = new Date();
        const newRange = event.target.value
        switch (newRange) {
            case '1Week':
                date.setDate(date.getDate() - 7);
                break;
            case '1Month':
                date.setMonth(date.getMonth() - 1);
                break;
            case '6Months':
                date.setMonth(date.getMonth() - 6);
                break;
            case '1Year':
                date.setMonth(date.getMonth() - 12);
                break;
        }

        // Reset filtered inventory
        setFilteredInventory(allInventory)

        setItemsSold(0)
        setRevenue(0)
        products.forEach((product) => {
            const offerId: string =
                product.offerData.bolCom === 1 ? product.offerData.offers[0].seller.id : product.offerData.offers[0].id;
            const trackerId = product.id + offerId;
            const currentInventory = allInventory.get(trackerId) as ProductInventory[];

            // Only filter if there is existing inventory
            if(currentInventory){

                let newFilteredInv = currentInventory
                if(newRange != 'All'){
                    newFilteredInv = currentInventory.filter(item => {
                        return item.updated >= date;
                    })
                }

                setItemsSold(itemsSold => {

                    let numberOfSales = 0;
                    if(newRange == 'All'){
                        numberOfSales = newFilteredInv[newFilteredInv.length - 1].numberOfSales
                    } else{
                        numberOfSales =
                            newFilteredInv[newFilteredInv.length - 1].numberOfSales - newFilteredInv[0].numberOfSales;
                    }

                    // Set revenue
                    setRevenue(revenue => {
                        return revenue + numberOfSales * product.offerData.offers[0].price
                    });

                    return itemsSold + numberOfSales
                });


            }

            // Set filtered Inventory
            setFilteredInventory(
                filteredInventory.set(
                    trackerId,
                    currentInventory
                )
            )
        });


        // Set date range string
        setRange(newRange)
    };

    /**
     * Searches products at Bol.com with the current input string.
     */
    const searchProducts = () => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/search/${search}`)
            .then((res) => res.json())
            .then((response) => {
                if (response.products) {
                    const newResults: Product[] = [];
                    response.products.forEach((result: Product) => {
                        if (!result.offerData.offers) {
                            //Producten zonder offers zijn tweedehands verkopers en hoeven niet meegenomen te worden.
                            return;
                        } else if (result.offerData.offers.length > 1) {
                            result.offerData.offers.forEach((offer) => {
                                let newResult: Product = JSON.parse(JSON.stringify(result));
                                newResult.offerData.offers = [offer];
                                if (offer.seller.sellerType === 'bol.com') {
                                    newResult.offerData.bolCom = 1;
                                    newResult.offerData.professionalSellers = 0;
                                } else {
                                    newResult.offerData.bolCom = 0;
                                    newResult.offerData.professionalSellers = 1;
                                }
                                newResults.push(newResult);
                            });
                        } else {
                            newResults.push(result);
                        }
                    });
                    setResults(newResults);
                } else {
                    alert('Geen producten gevonden voor deze zoekopdracht.');
                }
            })
            .catch((error) => console.error('Error: ', error));
    };

    /**
     * Adds the given product to the Firestore.
     * @param product The given product.
     */
    const addProduct = (product: Product) => {
        const currentUser: User = user;
        const newProduct: TrackedProduct = {
            productId: product.id,
            offerId:
                product.offerData.bolCom === 1 ? product.offerData.offers[0].seller.id : product.offerData.offers[0].id,
        };
        if (currentUser.products) {
            currentUser.products.push(newProduct);
        } else {
            currentUser.products = [newProduct];
        }
        doSetProducts(product, newProduct.offerId)
            .then(() => {
                doSetUsers(currentUser).catch((error) => console.error(error));
                doGetTrackers(product.id + newProduct.offerId)
                    .then((document) => {
                        if (document.exists) {
                            const count: number = document.data()!.count as number;
                            doSetTrackers(count + 1, product.id + newProduct.offerId).catch((error) =>
                                console.error('Error: ', error),
                            );
                        } else {
                            doSetTrackers(1, product.id + newProduct.offerId).catch((error) =>
                                console.error('Error: ', error),
                            );
                        }
                    })
                    .catch((error) => console.error('Error: ', error));
                alert(product.title + ' toegevoegd!');
                setResults([]);
                setSearch('');
                products.push(product);
                setProducts([...products]);
                setFilteredProducts(products);
            })
            .catch((error) => console.error('Error: ', error));
    };

    /**
     * Deletes the given product from the Firestore.
     * @param product The given product
     */
    const deleteProduct = (product: Product) => {
        const currentUser: User = user;
        const offerId: string =
            product.offerData.bolCom === 1 ? product.offerData.offers[0].seller.id : product.offerData.offers[0].id;
        const index: number = currentUser.products.findIndex((trackedProduct) => {
            return trackedProduct.productId === product.id && trackedProduct.offerId === offerId;
        });
        currentUser.products.splice(index, 1);
        doSetUsers(currentUser)
            .then(() => {
                doGetTrackers(product.id + offerId)
                    .then((document) => {
                        if (document.exists) {
                            const count: number = document.data()!.count as number;
                            doSetTrackers(count - 1, product.id + offerId).catch((error) =>
                                console.error('Error: ', error),
                            );
                        }
                    })
                    .catch((error) => console.error('Error: ', error));
                const index: number = products.indexOf(product);
                products.splice(index, 1);
                setProducts([...products]);
                setFilteredProducts(products);
            })
            .catch((error) => console.error(error));
    };

    /**
     * Displays countdown until server gets new inventory
     */
    const showTime = () => {
        const date: Date = new Date();
        const minutes: number = date.getMinutes();
        const seconds: number = date.getSeconds();

        if (minutes > 30) {
            setMinutes(60 - minutes);
        } else {
            setMinutes(30 - minutes);
        }

        setSeconds(60 - seconds);

        setTimeout(showTime, 1000);
    };

    /**
     * Runs the setSearch() function when the input string is longer than 2 characters.
     * It always keeps on updating the search string state.
     * @param input
     */
    const doSetSearch = (input: string) => {
        if (input.length > 2) {
            setSearch(input);
            searchProducts();
        } else {
            setSearch(input);
            setResults([]);
        }
    };

    /**
     * Adds category to the Firebase user object
     */
    const addCategory = () => {
        const newCategory: Category = {
            name: category,
            products: [],
        };
        if (user.categories) {
            user.categories.push(newCategory);
        } else {
            user.categories = [newCategory];
        }

        setUser(user);
        doSetUsers(user).catch((error) => console.error('Error: ', error));
    };

    /**
     * When the select dropdown is used change the given category for the user
     * @param user
     * @param category
     */
    const changeCategory = (user: User, category: string) => {
        setUser(user);
        const index: number = user.categories!.findIndex((cat) => {
            return cat.name === category;
        });
        if (
            currentCategory !== 'Alle' &&
            user.categories &&
            user.categories[index] &&
            user.categories[index].products
        ) {
            filterProducts(user.categories[index].products);
        } else if (currentCategory !== 'Alle') {
            setFilteredProducts([]);
        } else {
            setFilteredProducts(products);
        }
    };

    /**
     * Deletes given category from the Firestore user object
     * @param category
     */
    const deleteCategory = (category: Category) => {
        const index: number = user.categories!.findIndex((category2) => {
            return category2.name === category.name;
        });

        user.categories!.splice(index, 1);
        setUser(user);

        doSetUsers(user).catch((error) => console.error('Error: ', error));
    };

    /**
     * Changes the shown products with the given products
     * @param trackedProducts that needs to be shown
     */
    const filterProducts = (trackedProducts?: TrackedProduct[]) => {
        setFilteredProducts([]);
        if (trackedProducts) {
            const newFilteredProducts: Product[] = [];
            trackedProducts.forEach((trackedProduct) => {
                const index: number = products.findIndex((product) => {
                    const offerId: string =
                        product.offerData.bolCom === 1
                            ? product.offerData.offers[0].seller.id
                            : product.offerData.offers[0].id;
                    return trackedProduct.productId === product.id && trackedProduct.offerId === offerId;
                });
                if (index !== -1) {
                    newFilteredProducts.push(products[index]);
                }
            });
            setFilteredProducts(newFilteredProducts);
        }
    };

    /**
     * Runs the searchProducts() function when the Enter key is pressed.
     * @param event Key input
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter' && search.length !== 0) {
            searchProducts();
        }
    };

    /**
     * Filters all products when selectbox is changed
     * @param event the selected order of change
     */
    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        switch (event.target.value) {
            case 'revenue':
                const sortedRevenue = [...filteredProducts].sort((a, b) => {
                    const aOfferId: string =
                        a.offerData.bolCom === 1 ? a.offerData.offers[0].seller.id : a.offerData.offers[0].id;
                    const bOfferId: string =
                        b.offerData.bolCom === 1 ? b.offerData.offers[0].seller.id : b.offerData.offers[0].id;
                    const soldItemsA: number = productInventory.get(a.id + aOfferId)!;
                    const soldItemsB: number = productInventory.get(b.id + bOfferId)!;
                    return soldItemsB * b.offerData.offers[0].price - soldItemsA * a.offerData.offers[0].price;
                });
                setFilteredProducts(sortedRevenue);
                break;
            case 'sales':
                const sortedSales = [...filteredProducts].sort((a, b) => {
                    const aOfferId: string =
                        a.offerData.bolCom === 1 ? a.offerData.offers[0].seller.id : a.offerData.offers[0].id;
                    const bOfferId: string =
                        b.offerData.bolCom === 1 ? b.offerData.offers[0].seller.id : b.offerData.offers[0].id;
                    const soldItemsA: number = productInventory.get(a.id + aOfferId)!;
                    const soldItemsB: number = productInventory.get(b.id + bOfferId)!;
                    return soldItemsB - soldItemsA;
                });
                setFilteredProducts(sortedSales);
                break;
            default:
                window.location.reload();
                break;
        }
    };

    return (
        <section className="Tracker" onKeyDown={onKeyDownHandler}>
            <div className="search-row">
                <section className="search-row-container">
                    <p className="tracker-title">
                        Welkom terug,
                        <br />
                        {user.firstName}
                    </p>
                    <div className="input-wrapper-tracker">
                        <input
                            className="input-tracker"
                            placeholder="Zoek een product..."
                            value={search}
                            onChange={(event) => doSetSearch(event.target.value)}
                        />
                        <Search className="search-button" onClick={searchProducts} />
                    </div>
                </section>
            </div>
            <section className="container">
                {results && results.length !== 0 ? (
                    <>
                        <p className="result-title">Resultaat: </p>
                        <section className="product-container">
                            {results.map((product, index) => (
                                <ProductList
                                    result={true}
                                    buttonClick={addProduct}
                                    product={product}
                                    key={index}
                                    user={user}
                                />
                            ))}
                        </section>
                    </>
                ) : (
                    <>
                        <div className="container--range">
                            <button
                                className={range === '1Week' ? 'blue-button' : 'white-button'}
                                onClick={filterInventory}
                                value="1Week"
                            >
                                1 Week
                            </button>
                            <button
                                className={range === '1Month' ? 'blue-button' : 'white-button'}
                                onClick={filterInventory}
                                value="1Month"
                            >
                                1 Maand
                            </button>
                            <button
                                className={range === '6Months' ? 'blue-button' : 'white-button'}
                                onClick={filterInventory}
                                value="6Months"
                            >
                                6 Maanden
                            </button>
                            <button
                                className={range === '1Year' ? 'blue-button' : 'white-button'}
                                onClick={filterInventory}
                                value="1Year"
                            >
                                1 Jaar
                            </button>
                            <button
                                className={range === 'All' ? 'blue-button' : 'white-button'}
                                onClick={filterInventory}
                                value="All"
                            >
                                Alles
                            </button>
                        </div>
                        <div className="total-data">
                            <div className="total-data--card">
                                <p className="card-top-text">
                                    Totale Omzet
                                    <br /> Track Geschiedenis
                                </p>
                                <p className="card-bottom-text">€ {revenue.toFixed(2)}</p>
                            </div>
                            <div className="total-data--card">
                                <p className="card-top-text">Totaal Verkocht</p>
                                <p className="card-bottom-text">{itemsSold} Items</p>
                            </div>
                            <div className="total-data--card">
                                <p className="card-top-text">Aantal Producten</p>
                                <p className="card-bottom-text">{products.length}</p>
                            </div>
                            <div className="total-data--card">
                                <p className="card-top-text">Ververst data in</p>
                                <p className="card-bottom-text">
                                    {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
                                </p>
                            </div>
                        </div>
                        <div className="category-row">
                            <p className="add-category-title">Voeg een categorie toe</p>
                            <div className="row">
                                <input
                                    className="category-input"
                                    placeholder="Categorie..."
                                    type="text"
                                    name="Categorie"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                />
                                <button className="category-button" onClick={addCategory}>
                                    <Add /> Toevoegen
                                </button>
                            </div>
                        </div>
                        <div className="category-button-row">
                            <div className="button-row">
                                <p id="category-title">Categorie: </p>
                                <button
                                    className={
                                        currentCategory === 'Alle' ? 'yellow-category-button' : 'blue-category-button'
                                    }
                                    id="category-text"
                                    onClick={() => {
                                        setFilteredProducts(products);
                                        setCurrentCategory('Alle');
                                    }}
                                >
                                    Mijn Producten
                                </button>
                                {user.categories?.map((category, index) => {
                                    return (
                                        <div className="button-row" key={index}>
                                            <button
                                                className={
                                                    currentCategory === category.name
                                                        ? 'yellow-category-button'
                                                        : 'blue-category-button'
                                                }
                                                onClick={() => {
                                                    setCurrentCategory(category.name);
                                                    filterProducts(category.products);
                                                }}
                                            >
                                                {category.name}
                                            </button>
                                            <button
                                                className="delete-category-button"
                                                onClick={() => deleteCategory(category)}
                                            >
                                                <Delete />
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="sort-row">
                                <p className="sort-title">Sorteer op: </p>
                                <select className="sort-select" onChange={onSelect}>
                                    <option value="date">Datum Toegevoegd</option>
                                    <option value="revenue">Meeste Omzet</option>
                                    <option value="sales">Meeste Verkoop</option>
                                </select>
                            </div>
                        </div>
                        <section className="product-container">
                            {filteredProducts.map((product) => (
                                <ProductList
                                    result={false}
                                    buttonClick={deleteProduct}
                                    product={product}
                                    key={
                                        product.id +
                                        (product.offerData.bolCom === 1
                                            ? product.offerData.offers[0].seller.id
                                            : product.offerData.offers[0].id)
                                    }
                                    user={user}
                                    changeCategory={changeCategory}
                                />
                            ))}
                            {filteredProducts.length === 0 ? (
                                <div className="title">
                                    <p>Geen producten nog.</p>
                                </div>
                            ) : null}
                        </section>
                    </>
                )}
            </section>
        </section>
    );
};

export default Tracker;
