import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { BrowserRouter as Router } from 'react-router-dom';

// Initialize Firebase
firebase.initializeApp({
    apiKey: 'AIzaSyCI97lqo7p8ej81feuviCVJL5ViBpNw0PM',
    authDomain: 'tracker-4dce5.firebaseapp.com',
    projectId: 'tracker-4dce5',
    storageBucket: 'tracker-4dce5.appspot.com',
    messagingSenderId: '579338059067',
    appId: '1:579338059067:web:607edbbb877eabdc3e4b1e',
    measurementId: 'G-ZRMDY3EJW0',
});
firebase.analytics();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
