import React from 'react';
import './Loading.css';
import Menu from '../Menu/Menu';
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <>
            <Menu />
            <div className="container container--loading">
                <h1 className="title-loading">Loading...</h1>
                <ReactLoading type="bars" color="#000000" />
            </div>
        </>
    );
};

export default Loading;
