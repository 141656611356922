import React, { useState, useEffect } from 'react';
import './ProductOverview.css';
import User from '../../models/User';
import Product from '../../models/Product';
import ProductInventory from '../../models/ProductInventory';
import FirebaseInventory from '../../models/FirebaseInventory';
import Trustworthy from '../../models/Trustworthy';
import {
    doGetProductInventory,
    doGetProducts,
    doGetTrackers,
    doGetUsers,
    doSetTrackers,
    doSetUsers,
} from '../../service/FirebaseService';
import ReactLoading from 'react-loading';
import { Line } from 'react-chartjs-2';
import { Redirect, useParams } from 'react-router-dom';

interface useParamsProps {
    trackerId: string;
}

const ProductOverview = () => {
    const end: Date = new Date();
    const initUser: User = {
        firstName: '',
        lastName: '',
        products: [],
    };
    const [saleInfoString, setSaleInfoString] = useState<string>('Verkoopdata van de afgelopen 30 dagen');

    const params = useParams<useParamsProps>();
    const [user, setUser] = useState<User>(initUser);
    const [product, setProduct] = useState<Product>();
    const [range, setRange] = useState<string>('All');
    const [isStock, setIsStock] = useState<boolean>(true);
    const [isRedirect, setIsRedirect] = useState<boolean>(false);
    const [salesData, setSalesData] = useState({});
    const [stockData, setStockData] = useState({});
    const [inventory, setInventory] = useState<ProductInventory[]>([]);
    const [filteredInventory, setFilteredInventory] = useState<ProductInventory[]>([]);
    const [start, setStart] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [trust, setTrust] = useState<Trustworthy>();

    useEffect(() => {
        getUser();
        getProductState();
    }, []);

    /**
     * Navigates to the Tracker component
     */
    const back = () => {
        setIsRedirect(true);
    };

    if (isRedirect) {
        return <Redirect to="/" />;
    }

    /**
     * Gets the current logged in user from the Firestore
     */
    const getUser = () => {
        doGetUsers()
            .then((document) => {
                if (document.exists && document.data()) {
                    setUser(document.data() as User);
                }
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    };

    /**
     * Gets a product by the params from the url
     */
    const getProductState = async () => {
        if (product === undefined) {
            doGetProducts(params.trackerId)
                .then(async (document) => {
                    if (document.exists && document.data()) {
                        const newProduct: Product = document.data() as Product;
                        await setProduct(newProduct);
                        getAllInventory();
                    }
                })
                .catch((error) => console.error('Error: ', error));
        }
    };

    /**
     * Sets the data for the charts
     * @param inventory the given data
     */
    const chart = (inventory: ProductInventory[]) => {
        if (inventory.length !== 0) {
            const colorGreen = "rgb(43, 221, 198, 1)";
            const colorPurple = "rgb(134, 86, 246, 1)";
            const data = {
                dates: [] as string[],
                stock: [] as number[],
                sales: [] as number[],
            };
            inventory.forEach((item) => {
                data.dates.push(item.updated.toLocaleString());
                data.stock.push(item.inventory);
                data.sales.push(item.numberOfSales);
            });
            setSalesData({
                // Dates
                labels: data.dates,
                datasets: [
                    {
                        // Sales data
                        label: 'Sales',
                        data: data.sales,
                        backgroundColor: ['rgb(134,86,246, 0.4)'],
                        borderColor: colorPurple,
                        fill: true,
                    }
                ],
            });
            setStockData({
                // Dates
                labels: data.dates,
                datasets: [
                    {
                        // Stock data
                        label: 'Voorraad',
                        data: data.stock,
                        backgroundColor: ['rgb(43, 221, 198, .5)'],
                        borderColor: colorGreen,
                        fill: true
                    },
                ],
            });
        } else {
            setSalesData({});
            setStockData({});
        }
    };

    /**
     * Gets the inventory from the Firestore by the params from the url.
     */
    const getAllInventory = () => {
        doGetProductInventory(params.trackerId).then((document) => {
            if (document.exists && document.data()) {
                const inv = document.data()!.productInventory as FirebaseInventory[];
                let newInventory: ProductInventory[] = [];
                inv.forEach((item) => {
                    const newInv: ProductInventory = {
                        inventory: item.inventory,
                        price: item.price,
                        numberOfSales: item.numberOfSales,
                        updated: item.updated.toDate(),
                    };
                    newInventory.push(newInv);
                });
                setInventory(newInventory);
                setFilteredInventory(newInventory);
                chart(newInventory);
                trustIndication(newInventory.length);
            }
        });
    };

    /**
     * Get trust indication string
     * @param inventoryAmount
     */
    const trustIndication = (inventoryAmount: number) => {
        const TRUSTWORTHY = 'Betrouwbaar';
        if (inventoryAmount <= 240) {
            setTrust({
                title: 'Niet ' + TRUSTWORTHY,
                css: 'trust-red',
                tooltip:
                    'Dit product staat nog niet lang genoeg in ons systeem. Wacht een aantal dagen voor een betrouwbare indicatie.',
            });
        } else if (inventoryAmount > 240 && inventoryAmount < 480) {
            setTrust({
                title: TRUSTWORTHY,
                css: 'trust-orange',
                tooltip: 'Dit product is betrouwbaar. Hierdoor krijgt u een goede indicatie van dit product.',
            });
        } else if (inventoryAmount >= 480) {
            setTrust({
                title: TRUSTWORTHY,
                css: 'trust-green',
                tooltip: 'Dit product is betrouwbaar. Hierdoor krijgt u een goede indicatie van dit product.',
            });
        }
    };

    /**
     * Checks if there is data for the given date
     * @param date given date
     * @return boolean
     */
    const isDateBetween = (date: Date): boolean => {
        return date >= start && date <= end;
    };

    /**
     * Changes the date range of inventory on click
     * @param event the given date range
     */
    const onClick = (event: any) => {
        const date: Date = new Date();
        switch (event.target.value) {
            case '1Week':
                date.setDate(date.getDate() - 7);
                break;
            case '1Month':
                date.setMonth(date.getMonth() - 1);
                break;
            case '6Months':
                date.setMonth(date.getMonth() - 6);
                break;
            case '1Year':
                date.setMonth(date.getMonth() - 12);
                break;
            case 'All':
                getAllInventory();
                break;
        }
        setStart(date);
        setRange(event.target.value);
        setFilteredInventory(
            inventory.filter((item) => {
                return isDateBetween(new Date(item.updated));
            }),
        );
        setSaleInfoString(`Verkoopdata tussen ${formatDate(start)} en ${formatDate(end)}`);
        chart(filteredInventory);
    };

    /**
     * Changes the chart by given value
     * @param value given boolean value
     */
    const changeChart = (value: boolean) => {
        setIsStock(value);
    };

    /**
     * @return number of sales
     */
    const totalNumberOfSales = (): number => {
        if (filteredInventory.length !== 0) {
            return filteredInventory[filteredInventory.length - 1].numberOfSales;
        }
        return 0;
    };

    /**
     * Format date with month names
     * @param date
     */
    let formatDate = (date: Date): string => {
        const MONTH_NAMES = [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december',
        ];
        return [date.getDate(), MONTH_NAMES[date.getMonth()], date.getFullYear()].join(' ');
    };

    /**
     * Deletes a inventory entry for a product into the Firestore.
     */
    const deleteProduct = () => {
        const currentUser: User = user;
        const offerId: string =
            product!.offerData.bolCom === 1 ? product!.offerData.offers[0].seller.id : product!.offerData.offers[0].id;
        const index: number = currentUser.products.findIndex((trackedProduct) => {
            return trackedProduct.productId === product!.id && trackedProduct.offerId === offerId;
        });
        currentUser.products.splice(index, 1);
        doSetUsers(currentUser)
            .then(() => {
                doGetTrackers(product!.id + offerId)
                    .then((document) => {
                        if (document.exists) {
                            const count: number = document.data()!.count as number;
                            doSetTrackers(count - 1, product!.id + offerId).catch((error) =>
                                console.error('Error: ', error),
                            );
                        }
                    })
                    .catch((error) => console.error('Error: ', error));
                back();
            })
            .catch((error) => console.error(error));
    };

    return (
        <section className="ProductOverview">
            {product ? (
                <>
                    <div className="top-row">
                        <section className="container" id="top-row-container">
                            <div className="product-hexagon-image">
                                {product.images ? (
                                    <img src={product.images[3].url} alt={product.title} />
                                ) : (
                                    <p>Geen Foto</p>
                                )}
                            </div>
                            <div className="product-header-info">
                                <p className="product-title">{product.title}</p>
                                <p className="seller-title">
                                    Verkoop door: {product.offerData.offers[0].seller.displayName}
                                </p>
                                <div className="top-button-row">
                                    <a
                                        className="button-bol"
                                        href={product.urls[0].value}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Bekijk op bol.com
                                    </a>
                                    <button className="button-delete" onClick={deleteProduct}>
                                        Verwijder product
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="container" id="bottom-row-container">
                        <section>
                            <p className="sale-info-title">{saleInfoString}</p>
                            <div className="sales-data--container">
                                <div className="info-box">
                                    <p className="upper-text">Prijs</p>
                                    <p className="bottom-text">&euro;{product.offerData.offers[0].price}</p>
                                </div>
                                <div className="info-box">
                                    <p className="upper-text">Omzet</p>
                                    <p className="bottom-text">
                                        &euro;{totalNumberOfSales() * product.offerData.offers[0].price}
                                    </p>
                                </div>
                                <div className="info-box">
                                    <p className="upper-text">Aantal verkocht</p>
                                    <p className="bottom-text">{totalNumberOfSales()}</p>
                                </div>
                                <div className={trust?.css} id="trustworthy">
                                    <div className="trust-tooltip-icon">
                                        i
                                        <div className="trust-tooltip">
                                            <p>{trust?.tooltip}</p>
                                        </div>
                                    </div>
                                    <p className="trust-title">{trust?.title}</p>
                                </div>
                            </div>
                        </section>
                        <div className="chart-row" id="upper-chart-row">
                            <div className="chart-row" id="time-chart-row">
                                <button
                                    className={range === '1Week' ? 'blue-button' : 'white-button'}
                                    onClick={onClick}
                                    value="1Week"
                                >
                                    1 Week
                                </button>
                                <button
                                    className={range === '1Month' ? 'blue-button' : 'white-button'}
                                    onClick={onClick}
                                    value="1Month"
                                >
                                    1 Maand
                                </button>
                                <button
                                    className={range === '6Months' ? 'blue-button' : 'white-button'}
                                    onClick={onClick}
                                    value="6Months"
                                >
                                    6 Maanden
                                </button>
                                <button
                                    className={range === '1Year' ? 'blue-button' : 'white-button'}
                                    onClick={onClick}
                                    value="1Year"
                                >
                                    1 Jaar
                                </button>
                                <button
                                    className={range === 'All' ? 'blue-button' : 'white-button'}
                                    onClick={onClick}
                                    value="All"
                                >
                                    Alles
                                </button>
                            </div>
                            <div className="chart-row" id="chart-chart-row">
                                <button
                                    className={isStock ? 'blue-button' : 'white-button'}
                                    onClick={() => changeChart(true)}
                                >
                                    Voorraad
                                </button>
                                <button
                                    className={!isStock ? 'blue-button' : 'white-button'}
                                    onClick={() => changeChart(false)}
                                >
                                    Sales
                                </button>
                            </div>
                        </div>
                        {isStock ? (
                            <div className="chart-parent">
                                <Line
                                    data={stockData}
                                    width={50}
                                    height={25}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false
                                    }}
                                 type="line"/>
                            </div>
                        ) : (
                            <div className="chart-parent">
                                <Line
                                    data={salesData}
                                    width={50}
                                    height={25}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                    }}
                                 type="line"/>
                            </div>
                        )}
                    </section>
                </>
            ) : (
                <>
                    <nav className="container">
                        <button onClick={back}>Terug naar overzicht</button>
                    </nav>
                    <div className="container container--product-loading">
                        <h1>Product laden...</h1>
                        <ReactLoading type="bars" color="#000000" />
                    </div>
                </>
            )}
        </section>
    );
};

export default ProductOverview;
