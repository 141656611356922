import React, { useState } from 'react';
import './Login.css';
import { doLogin, doResetPassword } from '../../service/FirebaseService';
import logo from '../../img/logo-bollie.png';
import desktop from '../../img/desktop.png';

const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    /**
     * Logs user into Firebase Authentication.
     */
    const login = () => {
        doLogin(email, password).catch((error) => {
            alert(error.message);
        });
    };

    const reset = () => {
        let email: string | null = prompt('Voer uw e-mail in');

        if (email !== null && email !== '') {
            doResetPassword(email)
                .then(() => alert('Check uw inbox!'))
                .catch((error) => alert(error));
        }
    };

    /**
     * Runs the login() function when Enter key is pressed
     * @param event Button event
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter') {
            login();
        }
    };

    return (
        <div className="row">
            <section className="Login" onKeyDown={onKeyDownHandler}>
                <img src={logo} alt="logo-bollie" />
                <p className="login-title">Login</p>
                <p className="login-subtitle">Gebruikersnaam</p>
                <input
                    className="login-input"
                    placeholder="E-mail"
                    type="text"
                    name="e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="login-bar" />
                <p className="login-subtitle" id="subtitle-password">
                    Wachtwoord
                </p>
                <input
                    className="login-input"
                    placeholder="Wachtwoord"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="login-bar" />
                <div className="login-button-row">
                    <button className="button-password" onClick={reset}>
                        Wachtwoord vergeten?
                    </button>
                    <button className="button-login" onClick={login}>
                        Inloggen
                    </button>
                </div>
            </section>
            <section className="desktop-img" />
        </div>
    );
};

export default Login;
