import React, { useEffect, useState } from 'react';
import './ProductList.css';
import Product from '../../models/Product';
import { Redirect } from 'react-router-dom';
import { doGetProductInventory, doGetTrackers, doSetUsers } from '../../service/FirebaseService';
import FirebaseInventory from '../../models/FirebaseInventory';
import Trustworthy from '../../models/Trustworthy';
import User from '../../models/User';
import TrackedProduct from '../../models/TrackedProduct';
import { ReactComponent as Delete } from '../../img/delete.svg';

interface ProductListProps {
    product: Product;
    result: boolean;
    buttonClick: Function;
    user: User;
    changeCategory?: Function;
}

const ProductList: React.FC<ProductListProps> = ({ product, result, buttonClick, user, changeCategory }) => {
    const offerId: string =
        product.offerData.bolCom === 1 ? product.offerData.offers[0].seller.id : product.offerData.offers[0].id;
    const [trust, setTrust] = useState<Trustworthy>();
    const [trackers, setTrackers] = useState<number>(0);
    const [trackerId, setTrackerId] = useState<string>('');
    const [isRedirect, setIsRedirect] = useState<boolean>(false);
    const [averageSales, setAverageSales] = useState<number>(0);
    const [selected, setSelected] = useState<string>('geen');
    const [averageRevenue, setAverageRevenue] = useState<number>(0);

    /**
     * Either removes or adds the given product from user's tracked products in the Firestore.
     * @param product The given product.
     */
    const onClick = (product: Product) => {
        if (result) {
            window.location.href = product.urls[0].value;
        } else {
            setTrackerId(product.id + offerId);
            setIsRedirect(true);
        }
    };

    /**
     * Gets sales of the current product
     */
    const getSales = () => {
        doGetProductInventory(product.id + offerId)
            .then((document) => {
                if (document.exists) {
                    const inventory: FirebaseInventory[] = document.data()!.productInventory as FirebaseInventory[];
                    const soldItems: number = inventory[inventory.length - 1].numberOfSales;
                    const numberOfDays: number = Number((inventory.length / 48).toFixed(0));
                    const totalAverageSales: number = Number((soldItems / numberOfDays).toFixed(0));
                    numberOfDays === 0 ? setAverageSales(0) : setAverageSales(totalAverageSales);
                    numberOfDays === 0
                        ? setAverageRevenue(0)
                        : setAverageRevenue(product.offerData.offers[0].price * totalAverageSales);
                    trustIndication(inventory.length);
                }
            })
            .catch((error) => console.error('Error: ', error));
    };

    /**
     * Gets the number of trackers
     */
    const getTrackers = () => {
        doGetTrackers(product.id + offerId)
            .then((document) => {
                if (document.exists) {
                    const amount: number = document.data()!.count as number;
                    setTrackers(amount);
                }
            })
            .catch((error) => console.error('Error: ', error));
    };

    /**
     * Get trust indication string
     * @param inventoryAmount
     */
    const trustIndication = (inventoryAmount: number) => {
        const TRUSTWORTHY = 'Betrouwbaar';
        if (inventoryAmount <= 240) {
            setTrust({
                title: 'Niet ' + TRUSTWORTHY,
                css: 'trust-red',
                tooltip:
                    'Dit product staat nog niet lang genoeg in ons systeem. Wacht een aantal dagen voor een betrouwbare indicatie.',
            });
        } else if (inventoryAmount > 240 && inventoryAmount < 480) {
            setTrust({
                title: TRUSTWORTHY,
                css: 'trust-orange',
                tooltip: 'Dit product is betrouwbaar. Hierdoor krijgt u een goede indicatie van dit product.',
            });
        } else if (inventoryAmount >= 480) {
            setTrust({
                title: TRUSTWORTHY,
                css: 'trust-green',
                tooltip: 'Dit product is betrouwbaar. Hierdoor krijgt u een goede indicatie van dit product.',
            });
        }
    };

    /**
     * Formats each price into two digits
     */
    const priceFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    /**
     * Changes the given category
     * @param event the category
     */
    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value: string = event.target.value;
        setSelected(value);
        const trackedProduct: TrackedProduct = {
            productId: product.id,
            offerId,
        };
        if (user.categories) {
            user.categories.forEach((category) => {
                if (value === 'geen') {
                    const index: number = category.products!.findIndex((product) => {
                        return (
                            product.productId === trackedProduct.productId && product.offerId === trackedProduct.offerId
                        );
                    });
                    if (index !== -1) {
                        category.products!.splice(index, 1);
                        changeCategory!(user, category.name);
                        doSetUsers(user).catch((error) => console.error('Error: ', error));
                    }
                } else if (category.name === value) {
                    if (category.products) {
                        category.products.push(trackedProduct);
                    } else {
                        category.products = [trackedProduct];
                    }
                    changeCategory!(user, value);
                    doSetUsers(user).catch((error) => console.error('Error: ', error));
                }
            });
        }
    };

    /**
     * Gets the selected category for the product
     */
    const getSelected = () => {
        if (user.categories) {
            user.categories.forEach((category) => {
                let isSelected: boolean = false;

                category.products?.forEach((trackedProduct) => {
                    if (trackedProduct.productId === product.id && trackedProduct.offerId === offerId) {
                        isSelected = true;
                    }
                });

                if (isSelected) {
                    setSelected(category.name);
                    return;
                } else {
                    setSelected('geen');
                }
            });
        }
    };

    useEffect(() => {
        getSales();
        getTrackers();
        getSelected();
    }, [getTrackers()]);

    if (isRedirect) {
        return <Redirect to={`/product/${trackerId}`} />;
    }

    return (
        <div className="product-row">
            <button className="remove-product" onClick={() => buttonClick(product)}>
                <Delete />
            </button>
            <div className="product-image">
                <div className="product-hexagon" onClick={() => onClick(product)}>
                    {product.images ? <img src={product.images[3].url} alt={product.title} /> : <p>Geen Foto</p>}
                </div>
                <p className="price" onClick={() => onClick(product)}>
                    <strong>{priceFormatter.format(product.offerData.offers[0].price)}</strong>
                </p>
                <div className="trust-row" onClick={() => onClick(product)}>
                    <p>{product.specsTag}</p>
                    <div className={trust?.css} id="trust-wrapper">
                        <p className="trust-text">{trust?.title}</p>
                    </div>
                </div>
                <p className="product-card-title" onClick={() => onClick(product)}>
                    {product.title}
                </p>
                <div className="product-info">
                    {result ? (
                        <div onClick={() => onClick(product)}>
                            <p>{product.subtitle}</p>
                            <p className="description">{product.shortDescription}</p>
                            <p>Trackers: {trackers}</p>
                        </div>
                    ) : (
                        <>
                            <p onClick={() => onClick(product)}>
                                Gemiddelde Omzet: <strong>€ {priceFormatter.format(averageRevenue)}</strong>
                            </p>
                            <p onClick={() => onClick(product)}>
                                Gemiddelde Verkoop: <strong>{averageSales} items</strong>
                            </p>
                            <p onClick={() => onClick(product)}>
                                Trackers: <strong>{trackers}</strong>
                            </p>
                            {user.categories ? (
                                <div className="row">
                                    <p onClick={() => onClick(product)}>Categorie: </p>
                                    <select className="category-select" value={selected} onChange={onSelect}>
                                        <option value="geen">Geen</option>
                                        {user.categories.map((category, index) => {
                                            return (
                                                <option key={index} value={category.name}>
                                                    {category.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
                <div className="product-options">
                    <p onClick={() => onClick(product)}>
                        Verkoop door: <strong>{product.offerData.offers[0].seller.displayName}</strong>
                    </p>
                    {result ? (
                        <button className="button-track" onClick={() => buttonClick(product)}>
                            Product Tracken
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ProductList;
