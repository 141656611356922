import React from 'react';
import './PageNotFound.css';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className="container container--page-not-found">
            <h1>Pagina niet gevonden</h1>
            <Link to="/">Home</Link>
        </div>
    );
};

export default PageNotFound;
