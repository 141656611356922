import React, { useState } from 'react';
import './Register.css';
import User from '../../models/User';
import { doRegister, doResetPassword, doSetUsers, userId } from '../../service/FirebaseService';
import logo from '../../img/logo-bollie.png';
import Loading from '../Loading/Loading';

const Register = () => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    /**
     * Registers a new user into Firebase Authentication and Firestore
     * and immediately resets the users password
     */
    const register = () => {
        const number: number = Math.floor(Math.random() * 9999) + 1000;
        const password: string = 'Wachtwoord' + number;

        if (firstName !== '' && lastName !== '' && email !== '') {
            doRegister(email, password)
                .then(() => {
                    const user: User = {
                        firstName: firstName,
                        lastName: lastName,
                        products: [],
                    };

                    doSetUsers(user)
                        .then(() => doResetPassword(email).catch((error) => console.error('Error: ', error)))
                        .catch((error) => console.error('Error: ', error));
                })
                .catch((error) => console.error('Error: ', error));
        } else {
            alert('Niet alle velden zijn ingevoerd!');
        }
    };

    /**
     * Registers when the Enter key is pressed.
     * @param event Key input
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter') {
            register();
        }
    };

    return userId() === 'YNBA3kBzG3NSUA4QPL7y8A14j0D3' ? (
        <div className="row">
            <section className="Register" onKeyDown={onKeyDownHandler}>
                <img src={logo} alt="logo-bollie" className="register-logo" />
                <p className="register-title">Registreren</p>
                <p className="register-subtitle">Voornaam</p>
                <input
                    className="register-input"
                    placeholder="Voornaam"
                    type="text"
                    name="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <div className="register-bar" />
                <p className="register-subtitle second-subtitle">Achternaam</p>
                <input
                    className="register-input"
                    placeholder="Achternaam"
                    type="text"
                    name="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <div className="register-bar" />
                <p className="register-subtitle second-subtitle">E-mail</p>
                <input
                    className="register-input"
                    placeholder="E-mail"
                    type="text"
                    name="e-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="register-bar" />
                <button className="button-register" onClick={register}>
                    Registreer
                </button>
            </section>
            <section className="desktop-img" />
        </div>
    ) : (
        <Loading />
    );
};

export default Register;
